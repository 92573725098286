import React from "react"
import styled from "@emotion/styled"
import Scrollchor from 'react-scrollchor';

const NavLink = styled.p`
  color: #036;
  font-weight: 900;
  margin: 0 10px;
  display: inline-block;
  position: relative;
  text-decoration: none;
`

const HeaderLinks = styled.div`
  margin-left: auto;
  margin-top: 12px;
  margin-bottom: 0;
  font-size: 1.2rem;
  float: right;
`

const LinkDivider = styled.span`
  color: #FC6;   
  font-weight: 900;
  font-size: 1.2rem;
`

class FullWidthMenu extends React.Component {
  render () {
    return (
      <>
        <HeaderLinks>
          <LinkDivider>{""}</LinkDivider>
          <Scrollchor to="#mission">
            <NavLink>{'mission'}</NavLink>
          </Scrollchor>
          <LinkDivider>{""}</LinkDivider>
          <Scrollchor to="#featured">
            <NavLink>{'featured'}</NavLink>
          </Scrollchor>
          <LinkDivider>{""}</LinkDivider>
          <Scrollchor to="#projects">
            <NavLink>{'projects'}</NavLink>
          </Scrollchor>
        </HeaderLinks>
      </>
    )
  }
}

export default FullWidthMenu

/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import FullWidthMenu from "../components/menus/FullWidthMenu"
import HamburgerMenu from "../components/menus/HamburgerMenu"
import { FaHeart } from 'react-icons/fa';
import "./layout.css"

const Header = styled.div`
  margin: 0 auto;
  max-width: 100%;
  margin: 20px 1.0875rem 20px;
  overflow: hidden;
  min-height: 60px;
`

const Footer = styled.div`
  // display: flex;
  background-color: #f7f8fa;
  padding: 3rem 1.5rem 3rem;
  justify-content: middle;
  text-align: center;
`

const FooterText = styled.span`
  border-width: 10%;
  margin: 0 8px;
  font-size: 18px;
  color: black;
`

const FooterTextSmall = styled.span`
  margin: 0 8px;
  font-size: 14px;
  vertical-align: middle;
  display: inline-block;
`

const Body = styled.div`
  min-height: calc(100vh - 186px);
`

const HeaderLogoImage = styled.img`
  max-height: 3rem;
  float: left;
`

const FooterLogoImage = styled.img`
  max-height: 1.4rem;
  margin: 0 3px 4px 3px;
  vertical-align: middle;
`

const FooterIcon= styled.span`
  max-height: 1.4rem;
  padding-top: 2px;
  margin: 0 3px;
  vertical-align: middle;
  display: inline-block;
`

const Layout = ({ children }) => (
  <>
    <Header>
      <a href="https://opzsys.com/">
        <HeaderLogoImage src={"https://storage.googleapis.com/opzsys_static/opzsys_logo.svg"} />
      </a>
      <div className="full-width-menu">
        <FullWidthMenu siteTitle={"opzsys"} />
      </div>
      <div className="mobile-menu">
        <HamburgerMenu className={'right'} siteTitle={"opzsys"} />
      </div>
    </Header>
    <Body>
      {children}
    </Body>
    <Footer>
      <FooterText>
        {'Made with '}
        <FooterIcon>
          <FaHeart size={20} color={"#f14668"}/>
        </FooterIcon>{" by "}
        <FooterLogoImage src={"https://storage.googleapis.com/opzsys_static/opzsys_logo.svg"}/></FooterText>
      <FooterTextSmall>
        {"© "}{new Date().getFullYear()}{" One Page Zen Systems, LLC"}
      </FooterTextSmall>
    </Footer>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

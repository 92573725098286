import React from "react"
import styled from "@emotion/styled"
import Menu from 'react-burger-menu/lib/menus/slide'
// import { FaRegHandPeace, FaGithub, FaProjectDiagram } from 'react-icons/fa';
import Scrollchor from 'react-scrollchor';

const HeaderLinks = styled.div`
  margin-left: auto;
  padding: 0 !important;
`

const Icon = styled.span`
  color: #FC6 !important;
  margin-right: 3px;
  margin-bottom: 20px;
`

const styles = {
  /* Change 'right' to 'left' if you want menu icon
  to appear on the left side of the page */
  bmBurgerButton: {
    position: 'absolute',
    width: '36px',
    height: '30px',
    right: '36px',
    top: '30px',
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
    marginTop: '-20px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    width: '250px',
    position: 'fixed',
    height: '100%'
  },
  bmMenu: {
    background: '#373a47',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
    marginTop: '-20px'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    color: '#FFF',
    display: 'inline-block',
    textDecoration: 'none'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    marginLeft: '-20px',
    marginTop: '-20px'
  }
}

class HamburgerMenu extends React.Component {

  render () {
    return (
      <HeaderLinks>
        <Menu right styles={ styles }>
          <Icon><strong>{"#"}</strong></Icon><Scrollchor to="#mission">{'mission'}</Scrollchor><br/>
          <Icon><strong>{"#"}</strong></Icon><Scrollchor to="#featured">{'featured'}</Scrollchor><br/>
          <Icon><strong>{"#"}</strong></Icon><Scrollchor to="#projects">{'projects'}</Scrollchor><br/>
        </Menu>
      </HeaderLinks>
    )
  }
}

export default HamburgerMenu